div .main-div {
    display: flex;
}

div .L-sec {
    width: 10%;
}

div .R-sec {
    width: 10%;
}

div .C-sec {
    width: 80%;
    background-color: tomato;
}

nav.sliding-nav {
    left: 0;
    /* Initially hide the nav bar off the screen */
    /* width: 10px;
    height: 100%; */
    width: 0%;
    display: none;
    background-color: hsl(0, 0%, 26%);
    transition: left 0.1s ease;
}

nav.sliding-nav.open {
    left: 0;
    /* width: 100vw; */
    height: 100%;
    width: 100%;
    background-color: hsl(0, 0%, 26%);
    display: block;

    /* Slide the nav bar into view when it's open */
}

svg.toggle-btn {
    position: relative;
    top: 10px;
    left: 10px;
    /* background: none; */
    /* border: none; */
    cursor: pointer;
}

nav {
    padding-top: 100px;
}

nav ul {
    list-style-type: none;
    padding: 0;
}

nav ul li {
    padding: 10px;
    cursor: pointer;
    color: azure;
}

nav ul li:hover {
    background-color: #555;
}

div.sliding-note {
    position: relative;
    /* Initially hide the nav bar off the screen */
    width: 30px;
    height: 60vh;
    right: 0;
    background-color: rgb(255, 218, 185);
    transition: left 0.1s ease;
    border-radius: 10px;
}

div.sliding-note.open {
    position: absolute;
    width: 40%;
    height: 60vh;
    border-radius: 10px;
    background-color: rgb(255, 218, 185);
    right: 5px;
    box-shadow: 5px 5px 2px lightgray;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 0.5px solid gray;
    transition: 0.4s ease;
}

.item-1 {
    color: hsl(217, 10%, 50.8%);
}

.item-2 {
    background-color: orangered;
}

table.tableadmin {
    width: 100vw;
    height: 100vh;
}

table.tableadmin tr td {
    border: 1px solid;
}

td.tdmenu {
    width: 10%;
    vertical-align: top;
}

td.tdplaceholder {
    width: 90%;
    vertical-align: top;
}

div.sliding-note-footer {
    text-align: center;
}

div.notes-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 40%;
    background-color: white;
    border: 0.5px solid gray;
    padding: 3px;
    border-radius: 10px;
    width: 90%;
    padding: auto;
    justify-content: center;
    margin: 0 auto;
}

div.notes-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

p.notes-card-title {
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    color: #555;

}


p.notes-card-title-desc {
    text-align: left;
    font-size: 12px;
    color: #555;
    padding-left: 2px;

}

div.popup {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

}

div.popup-content {
    background-color: rgb(255, 218, 185);
    padding: 20px;
    border-radius: 5px;
    font-size: 12px;
}

span.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

span.close:hover,
span.close:focus {
    color: black;
    text-decoration: none;
}

table.Add-notes tr td {
    text-align: left;
}



textarea.txtdescription {
    width: 100%;
}

div.timesheet4 table tr td {
    /* background-color: aquamarine; */
    font-size: 10px;
    border-radius: 2px;
    text-align: center;
    /* border: 0.5px solid; */
}

div.timesheet4 thead {
    font-weight: bold;
    height: 300px;
}

div.timesheet4 thead tr td {
    /* background-color: rgb(255, 218, 185) !important; */
    box-sizing: border-box;
    font-size: 12px;
}

div.timesheet4 thead td.tdvertical label {
    /* transform: rotate(270deg);
    display: block;
    position: absolute;
    white-space: pre;
    transform-origin: 0 0;
    margin-left: 10px;
    word-wrap: break-word; */
    text-wrap: wrap;
    margin-left: 10px;
    width: 270px;
    word-break: break-word;
}

div.timesheet4 thead td.tdvertical1 label {
    /* transform: rotate(270deg);
    display: block;
    position: absolute;
    white-space: pre;
    transform-origin: 0 0;
    margin-left: 10px;
    word-wrap: break-word; */
    text-wrap: wrap;
    margin-left: 60px;
    width: 270px;
    word-break: break-word;
}

div.timesheet4 thead td.tdvertical {
    /* vertical-align: bottom; */
    position: relative;
    width: auto !important;

}

div.timesheet4 thead td.tdvertical1 {
    /* vertical-align: bottom; */
    position: relative;
    width: auto !important;

}

div.timesheet-dropdown {
    transform: rotate(270deg);
}

label.timesheet-dropdown {
    transform: rotate(270deg);
}

div.timesheet4 input {
    width: 100%;
}

/* div.timesheet4 select {
    width: 270px;
    text-wrap: wrap;
    margin-top: -12px;
    margin-left: 10px;
    background-color: lightgray !important;
    border: none;
    font-weight: bold;
} */

td.tdvertical select {
    width: 270px;
    text-wrap: wrap;
    margin-top: -10px;
    margin-left: 20px;
    background-color: lightgray !important;
    border: none;
    font-weight: bold;
}

td.tdvertical1 select {
    width: 270px;
    text-wrap: wrap;
    margin-top: -12px;
    margin-left: 10px;
    background-color: lightgray !important;
    border: none;
    font-weight: bold;
}

td.tdvertical option {
    background-color: none !important
}

td.tdvertical1 option {
    background-color: none !important
}


/* div.timesheet4 option {

    background-color: none !important
} */

td.timesheet4notes {
    width: 40%;
}

select.owndrop {
    font-size: 12px;
}

.form-control {
    font-size: 12px !important;
    /* border: none !important; */
    text-align: center;
}

div.timesheet4 tbody tr td {
    vertical-align: middle;
}

div.timesheetcontrols {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1px;

}

div.timesheet4 table thead.tablehead td {
    position: sticky;
    top: 0px;
}

tr.total-color td {
    background-color: lightgray;
}

tr.total-color td label {
    font-size: 12px;
    font-weight: bold;
    background-color: lightgray;
}

tr td.total-color {
    background-color: lightgray;
}

td.total-color label {
    font-size: 12px;
    font-weight: bold;
    background-color: lightgray;
}

div.project_control {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

td.tdhorizontal1 {
    text-align: center;
}

tbody.clienttable tr td {
    text-align: center;
}

div.ApproveTimesheet {
    font-size: 12px;
}

div.Reportcontrols {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

div.reportcontrolflex1 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

}

div.reportcontrolflex2 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

}

div.EffortReport {
    font-size: 12px;
}

div.ReportSection {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 5%;
}

tr.ReportTableHead td {
    background-color: lightgray !important;
    font-weight: bold;
}

tr.ReportFoot td {
    background-color: lightgray !important;
    font-weight: bold;
}

tr.ReportFoot label {
    background-color: lightgray !important;
    font-weight: bold;
}

div.AdminSection {
    font-size: 12px;
}

.Table-Header tr td {
    font-weight: bold;
}

div.EmpAdmin {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

div.admin-control {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: white;
}

div.admin-control button {
    color: tomato;
    font-size: 14px;
    background-color: white;
    /* rgb(245, 245, 245);*/
    font-weight: bold;
    text-shadow: 2px;
    border: none;
}

.wave-container {
    position: relative;
    width: 100%;
    height: 150px;
    overflow: hidden;
}

.waves {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.active {
    background-color: tomato;
}